.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #121212;
    color: #ffffff;
    padding: 1.25rem;
    margin-bottom: 0;
}
  
.header-name-and-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 2rem;
}

.header-name-and-icons a {
    color: #ffffff;
    transition: color 0.3s;
}

.header-name-and-icons a:hover {
    color: #bb86fc;
}
  
.header-nav .nav-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
  
.header-nav .nav-item a { 
    font-size: 1.2rem;
    margin-left: 1.1rem;
    cursor: pointer;
    transition: color 0.3s;
    text-decoration: none;
    color: #ffffff;
    position: relative;
    overflow: hidden; /* Ensures the pseudo-element doesn't overflow */
}

.header-nav .nav-item a::after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: #bb86fc;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
}

.header-nav .nav-item a:hover::after,
.header-nav .nav-item .active::after {
    width: 100%;
    left: 0;
}

.nav-item:not(.active):hover::after {
    width: 50%; /* Preview effect on hover */
    left: 25%; /* Center the preview effect */
}
  
.header-nav .nav-item:hover {
    color: #bb86fc; 
}

.nav-item .active {
    position: relative;
}
  
.nav-item .active::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background: #bb86fc;
    position: absolute;
    bottom: -5px; 
    left: 0;
    box-shadow: 0 0 8px #bb86fc;
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
}
  
.nav-item:not(.active)::after {
    width: 0%;
    left: 50%;
}

@media (max-width: 768px) {
    .Header {
        flex-direction: column;
        align-items: center;
    }

    .header-name-and-icons {
        flex-direction: row; 
        justify-content: center; 
        align-items: center; 
    }

    .header-nav .nav-list {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .header-nav .nav-item a { 
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}