.contact-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 20px;
  gap: 20px;
  flex-direction: row; 
}

.map {
  height: 400px;
  width: 600px;
  flex-shrink: 0; 
}

.contact-form {
  background-color: #1c1e21;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px; 
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  transition: transform 0.3s ease; 
  position: relative;
}

.contact-form h2, .contact-form form, .contact-alternative {
  width: 100%; 
}

.contact-form h2 {
  color: #bb86fc;
  margin-bottom: 15px; 
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.contact-form input, .contact-form textarea, .contact-form button {
  width: calc(100% - 30px);
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

.contact-form textarea {
  resize: none;
  height: 10rem;
}

.contact-form button {
  background-color: #bb86fc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #9a4dbb;
}

.contact-alternative {
  text-align: center;
}

.or-text {
  font-size: 1.2rem;
  color: #bb86fc;
  margin-bottom: 10px;
}

.submission-success-message {
  color: #4CAF50;
  margin: 10px 0;
  font-size: 1.2rem;
}

.submission-status-image {
  max-width: 100px;
  height: auto;
  margin: 10px 0;
}

.map {
  height: 400px;
  width: 4;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column; 
  }

  .map {
    width: 100%; 
    height: 300px; 
  }

  .contact-form {
    width: 100%;
    max-width: none; 
  }

  .contact-form input, .contact-form textarea, .contact-form button {
    width: calc(100% - 20px); 
  }
}