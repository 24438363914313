.projects-container {
    padding: 20px;
    background-color: #121212;
    color: #e0e0e0; 
}

.projects-filter {
    width: 200px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #1c1e21;
    color: #e0e0e0;
    border: 1px solid #333;
}
  
.project-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.project-card {
    background-color: #1c1e21;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    transition: transform 0.3s ease;
    position: relative;
}
  
.project-card:hover {
    transform: translateY(-5px);
}
  
.project-card img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}
  
.project-card h3 {
    margin-top: 10px;
    color: #bb86fc; 
}
  
.project-card p {
    font-size: 0.9rem;
}
  
.project-languages {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 5px;
}
  
.project-languages li {
    background-color: #bb86fc;
    color: #121212;
    padding: 5px;
    border-radius: 3px;
    font-size: 0.8rem;
}

.github-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 24px; 
    height: 24px;
}

.github-icon img {
    width: 100%;
    height: auto;
}