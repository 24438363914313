.resume-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  color: #e0e0e0;
  background-color: #121212;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.resume-header h1 {
  font-size: 2.5rem;
  color: #bb86fc;
  text-align: center;
}

.resume-header p {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 20px;
}

.resume-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.resume-section, .skills-section, .education-section {
  background-color: #1c1e21;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

h2 {
  font-size: 1.8rem;
  color: #bb86fc;
  margin-bottom: 10px;
}

.experience-section ul {
  padding-left: 20px;
  text-align: left;
}

.experience-section ul li {
  margin-bottom: 5px; 
}

.skills-section, .education-section {
  background-color: #1c1e21;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.skills-section {
  text-align: center;
}

.skills-list-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.skills-list {
  list-style: disc outside; 
  padding-left: 20px;
}

.skills-list li {
  color: #e0e0e0; 
  padding: 5px;
  margin-bottom: 5px; 
  text-align: left;
}

@media (max-width: 768px) {
  .resume-body {
    grid-template-columns: 1fr; 
    gap: 10px; 
  }

  .resume-header {
    margin-bottom: 10px; 
  }

  .resume-section, .skills-section, .education-section {
    margin-bottom: 10px; 
  }
}
