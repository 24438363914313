body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #121212; 
    color: #e0e0e0; 
}
  
.App {
    text-align: center;
}
  
.App-header {
    background-color: #1c1e21; 
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 1rem;
}
  
.navigation ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}
  
.navigation li {
    margin: 0 10px;
    color: #e0e0e0; 
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s ease;
}
  
.navigation li:hover {
    color: #bb86fc;
}
  
.Link {
    text-decoration: none;
    color: inherit; 
}
  
section {
    padding: 20px;
    margin-top: 20px;
}

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e0e0e0;
    background-color: #121212;
    min-height: calc(75vh - 2rem);
}
  
.home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
  
.profile-pic {
    width: 300px;
    height: 300px;
    border-radius: 60%;
    object-fit: cover;
    margin-right: 20px;
}
  
.intro-text {
    max-width: 600px;
    text-align: left;
}

@keyframes fadeInUp {
from {
    opacity: 0;
    transform: translateY(20px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}
  
.fadeInUp {
animation-name: fadeInUp;
animation-duration: 1.5s; /* Increased from 1s to 1.5s */
animation-fill-mode: both; /* Keeps the end state after the animation */
}
  

@media (max-width: 768px) {
  .home-content {
      flex-direction: column;
      text-align: center;
  }
  
  .profile-pic {
      width: 300px;
      height: 300px;
      margin-right: 0;
      margin-bottom: 10px;
  }
  
  .intro-text {
      max-width: 100%;
      text-align: center;
  }
}